import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["link"]

  connect() {
    this.linkTargets.forEach(link => {
      link.addEventListener("click", this.handleClick.bind(this));
    });

    // Check URL on load
    this.activateLinkFromHash();
  }

  disconnect() {
    this.linkTargets.forEach(link => {
      link.removeEventListener("click", this.handleClick.bind(this));
    });
  }

  handleClick(event) {
    const clickedLink = event.target;
    this.setActiveLink(clickedLink);
  }

  setActiveLink(link) {
    this.linkTargets.forEach(link => link.classList.remove("active"));
    link.classList.add("active");
  }

  activateLinkFromHash() {
    const hash = window.location.hash;
    if (hash) {
      const targetLink = this.linkTargets.find(link => link.getAttribute("href") === hash);
      if (targetLink) {
        this.setActiveLink(targetLink);
      }
    }
  }
}
import { Controller } from "@hotwired/stimulus";
import debounced from "debounced";

export default class extends Controller {
  connect() {
    debounced.initialize({ input: { wait: 1000 }, keyup: { wait: 1000 } });
    this.element.addEventListener("input", (e) => { this.handleInput(e); });
    this.autogrow(this.element);
  }

  disconnect() {
    this.element.removeEventListener("input", this.handleInput);
  }

  handleInput(event) {
    this.autogrow(event.target);
  }

  autogrow(textarea) {
    textarea.style.overflow = 'hidden';
    textarea.style.transition = 'height 0.3s cubic-bezier(0.4, 0.0, 0.2, 1)';
    textarea.style.height = 'auto';
    textarea.style.height = textarea.scrollHeight + 'px';
  }
}